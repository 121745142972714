import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import useToggle from "@hooks/useToggle";
import { Transition } from "@headlessui/react";

const MobileDropdown = ({ title, className, children, dark }) => {
  const [open, setOpen] = useToggle();
  return (
    <div className="">
      <button
        type="button"
        onClick={setOpen}
        className={`flex w-full flex-row items-center space-x-1 border-transparent  py-2 pl-3 pr-4  text-left text-base  font-medium ${dark
            ? "text-slate-300 hover:text-slate-200"
            : "text-slate-600  hover:text-slate-800"
          }   ${className} `}
      >
        <span className="text-lg text-slate-600">{title}</span>
        {open ? (
          <ChevronUpIcon className="h-6 w-6" />
        ) : (
          <ChevronDownIcon className="h-6 w-6" />
        )}
      </button>
      <Transition
        enter="transition-opacity duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        show={open}
      >
        <div>{children}</div>
      </Transition>
    </div>
  );
};

export default MobileDropdown;
