import Link from "next/dist/client/link";
import { Popover } from "@headlessui/react";
import SVG from "react-inlinesvg";
import { capitalize } from "lodash-es";

const MobileMenuItem = ({ title, href, active, icon, className, dark, close }) => {
  return (
    <Popover.Button onClick={() => close()}
      className={` flex w-full flex-row items-center space-x-2 py-2 pl-3 pr-4  text-left text-base font-medium ${dark ? "text-slate-300" : "text-gray-700"
        } ${className}  ${active
          ? "border-l-4 border-ifixers-500 bg-ifixers-50 "
          : "border-transparent hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
        } `}
    >
      <SVG
        src={icon}
        className={`h-6 w-6 ${dark ? "stroke-slate-300" : "stroke-slate-600"}`}
      />
      <Link href={href}>
        <a className="text-lg text-slate-600">{capitalize(title)}</a>
      </Link>
    </Popover.Button>
  );
};

export default MobileMenuItem;
