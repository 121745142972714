import { round } from "lodash-es";

export const initialState = {
  cart: [],
  totalCartItems: 0,
  totalUniqueCartItems: 0,
  subTotalEx: 0,
  subTotalInc: 0,
  tax: 0,
  openCart: false,
};

export const AppReducer = (state, action) => {
  switch (action.type) {
    case "init_stored": {
      return action.value;
    }
    case "cart.open": {
      return { ...state, openCart: true };
    }
    case "cart.close": {
      return { ...state, openCart: false };
    }
    case "cart.add": {
      const newCart = [...state.cart, action.value];
      const subInc = newCart
        .reduce((total, product) => total + product.price, 0)
        .toFixed(2);
      const subEx = round(subInc / 1.21, 2);
      return {
        ...state,
        cart: newCart,
        totalCartItems: newCart.length + 1,
        subTotalInc: subInc,
        subTotalEx: subEx,
        tax: round(subInc * 0.21, 2),
      };
    }

    case "cart.remove": {
      const newCart = state.cart.filter((item) => item != action.value);
      const subInc = newCart
        .reduce((total, product) => total + product.price, 0)
        .toFixed(2);
      const subEx = round(subInc / 1.21, 2);
      return {
        ...state,
        cart: newCart,
        totalCartItems: newCart.length + 1,
        subTotalInc: subInc,
        subTotalEx: subEx,
        tax: round(subInc * 0.21, 2),
      };
    }
    default:
      console.error("App reducer error: 'No such action type'");
  }
};
