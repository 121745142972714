import { capitalize } from "lodash-es";
import Link from "next/link";

const MenuItem = ({ text, href, className, dark }) => {
  return (
    <Link href={href}>
      <a
        className={` inline-flex items-center border-indigo-500 text-base font-semibold ${dark
          ? "text-slate-300 hover:text-slate-200"
          : "text-gray-600 hover:text-gray-800"
          }`}
      >
        {text == 'B2B' ? text : capitalize(text)}
      </a>
    </Link>
  );
};

export default MenuItem;
