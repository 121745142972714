import Header from "../header/header.js";
import Footer from "../footer/footer";
import { Fragment } from "react";
import ConsentModal from "../modals/consentModal";
import { useCookies } from "react-cookie";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import HeaderCentered from "../banners/HeaderCentered.js";
//import headerData from "../header/header-data.preval";
import headerData from "/data/header"
const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const router = useRouter();
  return (
    <Fragment>
      <div className="flex flex-col h-screen ">
        <HeaderCentered data={headerData[router.locale].header[0]} />
        <Header />
        <motion.main
          className="flex-grow"
          key={router.route}
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
            },
          }}
        >
          {children}
        </motion.main>
        <Footer />
        <ConsentModal
          state={cookies.cookieConsent === undefined ? true : false}
          setCookie={setCookie}
        />
      </div>
    </Fragment>
  );
};

export default Layout;
