import Link from "next/link";
import StyledLink from "../atoms/styledLink";
import LanguageDropdownMenu from "@components/formElements/inputs/select/LanguageDropdownMenu";
import { useRouter } from "next/router";
//import footerData from "./footer-data.preval";
import footerData from "data/footer";
const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/iFixers.be",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/ifixers_be/",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  const router = useRouter();
  const data = footerData[router.locale];
  return (
    <footer
      className="mt-16 bg-slate-900 pt-16 pb-8 lg:mt-24 justify-self-end "
      aria-labelledby="footer-heading"
    >
      <h6 id="footer-heading" className="sr-only">
        Footer
      </h6>
      <div className="container">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-3 xl:col-span-1">
            <Link href="/">
              <a className="text-3xl font-bold text-ifixers transition hover:text-ifixers-700 ">
                iFixers.
              </a>
            </Link>
            <p className="text-base font-medium text-slate-200">
              Herstellen was nog nooit zo eenvoudig.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-slate-200 transition hover:text-slate-300"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {data.footer.slice(0, 2).map((section, index) => (
                <div
                  key={section.id}
                  className={`${index != 0 ? "mt-12 md:mt-0" : ""}`}
                >
                  <h3 className="text-sm font-semibold uppercase tracking-wider text-slate-200">
                    {section.label}
                  </h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {section.links.map((item) => (
                      <li key={item.label}>
                        <Link href={item.href}>
                          <a className="text-base text-slate-400 hover:text-slate-300">
                            {item.label}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {data.footer
                .slice(2, data.footer.length)
                .map((section, index) => (
                  <div
                    key={section.id}
                    className={`${index != 0 ? "mt-12 md:mt-0" : ""}`}
                  >
                    <h3 className="text-sm font-semibold uppercase tracking-wider text-slate-200">
                      {section.label}
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {section.links.map((item) => (
                        <li key={item.label}>
                          <Link href={item.href}>
                            <a className="text-base text-slate-400 hover:text-slate-300">
                              {item.label}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              <div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold uppercase tracking-wider text-slate-200">
                    Taal
                  </h3>
                  <div className="mt-4 space-y-4">
                    <LanguageDropdownMenu dark={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" mx-auto mt-8  flex flex-col items-center justify-center space-y-2 border-t border-slate-700 pt-8  text-sm text-gray-400 dark:text-gray-500">
          <p className="text-center">
            <StyledLink
              className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
              text="Privacy"
              href="/privacy"
            />
            {"  "}-{"  "}
            <StyledLink
              className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
              text="Verkoopsvoorwaarden"
              href="/verkoopsvoorwaarden"
            />{" "}
            -{" "}
            <StyledLink
              className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
              text="disclaimer"
              href="/disclaimer"
            />
          </p>
          <p className="text-center text-sm font-medium text-gray-400 dark:text-gray-500">
            Copyright © 2022 PHONETECH BV All rights reserved.
          </p>
          <p className="text-sm font-medium text-gray-400 dark:text-gray-500">
            BE 0848.759.106 Bondgenotenlaan 175, 3000 Leuven, België
          </p>
        </div>
      </div>
    </footer>
  );
}
