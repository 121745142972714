const data = {
    nl: {
        header: [
            {
                message: 'Na elke uitgevoerde herstelling krijgt u één uur gratis parkeren op parking De Bond (aan het station, op 20m van onze winkel) aangeboden.',
                email: { email: 'info@ifixers.be' },
                phone: {
                    id: '1',
                    formatted: '016 23 77 74',
                    extension: '+32',
                    number: '016237774'
                },
                social_medias: [
                    {
                        icon: null,
                        platform: 'Instagram',
                        url: 'https://www.instagram.com/ifixers_be/'
                    },
                    {
                        icon: '<svg\n' +
                            '          width="18"\n' +
                            '          height="18"\n' +
                            '          fill="currentColor"\n' +
                            '          version="1.1"\n' +
                            '          viewBox="0 0 64 64"\n' +
                            '        >\n' +
                            '          <g id="WA_Logo">\n' +
                            '            <g>\n' +
                            '              <path\n' +
                            '                d="M54,9.9c-5.8-5.8-13.7-9-21.8-9C15.2,0.9,1.3,14.7,1.3,31.7c0,5.5,1.4,10.7,4.1,15.5L1,63.1l16.5-4.2\n' +
                            '\t\t\tc4.5,2.4,9.6,3.8,14.8,3.8l0,0l0,0C49.2,62.6,63,48.8,63,31.7C63,23.5,59.8,15.8,54,9.9z M32.1,57.4L32.1,57.4\n' +
                            '\t\t\tc-4.5,0-9.2-1.3-13.1-3.7l-1-0.6l-9.7,2.5l2.7-9.4l-0.6-1c-2.5-4.1-3.9-8.9-3.9-13.7c0-14.1,11.4-25.5,25.6-25.5\n' +
                            '\t\t\tc6.8,0,13.2,2.7,18,7.5s7.5,11.3,7.5,18.2C57.8,46,46.2,57.4,32.1,57.4z M46.2,38.2c-0.8-0.4-4.5-2.3-5.4-2.4\n' +
                            '\t\t\tc-0.7-0.3-1.3-0.4-1.7,0.4c-0.4,0.8-2,2.4-2.4,3c-0.4,0.4-0.8,0.6-1.7,0.1c-0.8-0.4-3.2-1.1-6.2-3.9c-2.3-2-3.9-4.5-4.2-5.4\n' +
                            '\t\t\tc-0.4-0.8-0.1-1.1,0.4-1.6c0.4-0.4,0.8-0.8,1.1-1.4c0.4-0.4,0.4-0.8,0.8-1.3c0.4-0.4,0.1-1-0.1-1.4c-0.3-0.4-1.7-4.2-2.4-5.8\n' +
                            '\t\t\tc-0.6-1.6-1.3-1.3-1.7-1.3c-0.4,0-1,0-1.4,0s-1.4,0.1-2,1c-0.7,0.8-2.7,2.7-2.7,6.5s2.7,7.3,3.2,8c0.4,0.4,5.5,8.3,13.1,11.7\n' +
                            '\t\t\tc1.8,0.8,3.2,1.3,4.4,1.7c1.8,0.6,3.5,0.4,4.8,0.3c1.5-0.1,4.5-1.8,5.2-3.7c0.6-1.7,0.6-3.4,0.4-3.7\n' +
                            '\t\t\tC47.5,38.8,46.9,38.5,46.2,38.2z"\n' +
                            '              />\n' +
                            '            </g>\n' +
                            '          </g>\n' +
                            '        </svg>',
                        platform: 'Whatsapp',
                        url: 'https://wa.me/32498313016'
                    }
                ],
                links: [{ id: '13', label: 'Over ons', href: '/over-ons' }]
            },
            {
                dropdowns: [
                    {
                        id: '2',
                        label: 'Aanbod herstellingen',
                        order: 1,
                        sections: [
                            {
                                id: '1',
                                label: 'Herstellingen',
                                description: '',
                                icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                    '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                    '</svg>',
                                order: 0,
                                links: [
                                    {
                                        id: '4',
                                        label: 'Tablets',
                                        href: '/herstellingen/tablets',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/tablets',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '6',
                                        label: 'Computers',
                                        href: '/computers',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/computers',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '5',
                                        label: 'Smartwatches',
                                        href: '/herstellingen/smartwatches',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/smartwatches',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '1',
                                        label: 'Smartphones',
                                        href: '/herstellingen/smartphones',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/smartphones',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '47',
                                        label: 'Game consoles',
                                        href: '/herstellingen/gameconsoles',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/gameconsoles',
                                        showIsNewTag: true
                                    }
                                ]
                            }
                        ]
                    }
                ],
                ordered_link: [
                    {
                        id: '2',
                        link: {
                            id: '19',
                            label: 'Status bekijken',
                            href: '/status',
                            description: 'Bekijk de status van uw herstelling'
                        },
                        order: 3
                    },
                    {
                        id: '3',
                        link: {
                            id: '22',
                            label: 'Contact',
                            href: '/contact',
                            description: 'Neem contact met ons op'
                        },
                        order: 4
                    },
                    {
                        id: '10',
                        link: {
                            id: '37',
                            label: 'Herstelling aanmelden',
                            href: '/checkout',
                            description: null
                        },
                        order: 2
                    },
                    {
                        id: '12',
                        link: { id: '50', label: 'B2B', href: '/b2b', description: null },
                        order: 5
                    }
                ]
            }
        ],
    },
    en: {
        header: [
            {
                message: 'After each finished repair, you will be offered one hour of free parking in parking De Bond (at the station, 20m from our store).',
                email: { email: 'info@ifixers.be' },
                phone: {
                    id: '1',
                    formatted: '016 23 77 74',
                    extension: '+32',
                    number: '016237774'
                },
                social_medias: [
                    {
                        icon: null,
                        platform: 'Instagram',
                        url: 'https://www.instagram.com/ifixers_be/'
                    }
                ],
                links: [{ id: '14', label: 'About us', href: '/about-us' }]
            },
            {
                dropdowns: [
                    {
                        id: '3',
                        label: 'Repairs',
                        order: 1,
                        sections: [
                            {
                                id: '22',
                                label: 'repairs',
                                description: null,
                                icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                    '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                    '</svg>',
                                order: 0,
                                links: [
                                    {
                                        id: '7',
                                        label: 'Tablets',
                                        href: '/repairs/tablets',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/tablets',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '9',
                                        label: 'Computers',
                                        href: '/computers',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/computers',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '11',
                                        label: 'Smartwatches',
                                        href: '/repairs/smartwatches',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/smartwatches',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '2',
                                        label: 'Smartphones',
                                        href: '/repairs/smartphones',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/smartphones',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '48',
                                        label: 'Game consoles',
                                        href: '/repairs/gameconsoles',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/gameconsoles',
                                        showIsNewTag: true
                                    }
                                ]
                            }
                        ]
                    }
                ],
                ordered_link: [
                    {
                        id: '5',
                        link: {
                            id: '20',
                            label: 'Status',
                            href: '/status',
                            description: 'View the status of your repair'
                        },
                        order: 3
                    },
                    {
                        id: '6',
                        link: {
                            id: '23',
                            label: 'Contact',
                            href: '/contact',
                            description: 'Talk to us'
                        },
                        order: 4
                    },
                    {
                        id: '11',
                        link: {
                            id: '38',
                            label: 'Request repair',
                            href: '/checkout',
                            description: null
                        },
                        order: 2
                    },
                    {
                        id: '13',
                        link: { id: '51', label: 'B2B', href: '/b2b', description: null },
                        order: 5
                    }
                ]
            }
        ]
    },
    fr: {
        header: [
            {
                message: 'Après chaque réparation, nous vous offrons une heure de stationnement gratuit au parking De Bond (à la gare, à 20m de notre magasin).',
                email: { email: 'info@ifixers.be' },
                phone: {
                    id: '1',
                    formatted: '016 23 77 74',
                    extension: '+32',
                    number: '016237774'
                },
                social_medias: [
                    {
                        icon: null,
                        platform: 'Instagram',
                        url: 'https://www.instagram.com/ifixers_be/'
                    }
                ],
                links: [
                    {
                        id: '15',
                        label: 'Qui sommes nous',
                        href: '/qui-sommes-nous'
                    }
                ]
            },
            {
                dropdowns: [
                    {
                        id: '5',
                        label: 'réparations',
                        order: 1,
                        sections: [
                            {
                                id: '23',
                                label: 'réparations',
                                description: null,
                                icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                    '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                    '</svg>',
                                order: 0,
                                links: [
                                    {
                                        id: '10',
                                        label: 'Ordinateurs',
                                        href: '/ordinateurs',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/computers',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '12',
                                        label: 'Smartwatches',
                                        href: '/réparations/smartwatches',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/smartwatches',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '8',
                                        label: 'Tablets',
                                        href: '/réparations/tablets',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/tablets',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '3',
                                        label: 'Smartphones',
                                        href: '/réparations/smartphones',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/smartphones',
                                        showIsNewTag: null
                                    },
                                    {
                                        id: '49',
                                        label: 'Consoles de jeux',
                                        href: '/réparations/gameconsoles',
                                        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />\n' +
                                            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
                                            '</svg>',
                                        main_href: '/repairs/gameconsoles',
                                        showIsNewTag: true
                                    }
                                ]
                            }
                        ]
                    }
                ],
                ordered_link: [
                    {
                        id: '8',
                        link: {
                            id: '21',
                            label: 'Statut',
                            href: '/statut',
                            description: null
                        },
                        order: 2
                    },
                    {
                        id: '9',
                        link: {
                            id: '24',
                            label: 'Contact',
                            href: '/contact',
                            description: 'Talk to us'
                        },
                        order: 3
                    },
                    {
                        id: '14',
                        link: {
                            id: '39',
                            label: 'Demander une réparation',
                            href: '/checkout',
                            description: null
                        },
                        order: 2
                    },
                    {
                        id: '15',
                        link: { id: '52', label: 'B2B', href: '/b2b', description: null },
                        order: 6
                    }
                ]
            }
        ]
    }
}


export default data;








