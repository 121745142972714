const SEO = {
  title: "Smartphone, tablet, computer en console reparatie.",
  titleTemplate: "%s | iFixers",
  description:
    "Uw smartphone, tablet, computer of console hersteld in 60 min, kom langs in Leuven zonder afspraak of laat je toestel ophalen in heel Belgie!",
  openGraph: {
    url: "https://ifixers.be",
    title: "iFixers | Smartphone, tablet, computer en console reparatie.",
    description:
      "Uw smartphone, tablet, computer of console hersteld in 60 min, kom langs in Leuven zonder afspraak of laat je toestel ophalen in heel Belgie!",
    type: "website",
    site_name: "iFixers",
  },
  additionalLinkTags: [
    {
      rel: "apple-touch-icon",
      href: "/apple-touch-icon.png",
      sizes: "180x180",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon-32x32.png",
      sizes: "32x32",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon-16x16.png",
      sizes: "16x16",
    },
    {
      rel: "mask-icon",
      href: "/safari-pinned-tab.svg",
      color: "#00AEEF",
    },
  ],
  additionalMetaTags: [
    { name: "theme-color", content: "#00AEEF" },
    { name: "viewport", content: "width=device-width, initial-scale=1.0" },
    {
      name: "google-signin-client_id",
      content: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
    },
    { name: "apple-mobile-web-app-title", content: "iFixers" },
    { name: "application-name", content: "iFixers" },
    { name: "msapplication-TileColor", content: "#00AEEF" },
  ],
};

export default SEO;
