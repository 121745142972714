import { createContext, ReactNode, useContext, useState } from "react";

type themeContextType = {
  isDark: boolean;
};

type Props = {
  children: ReactNode;
};

const themeContextDefaultValues: themeContextType = {
  isDark: false,
};

const ThemeContext = createContext<themeContextType>(themeContextDefaultValues);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export function ThemeProvider({ children }: Props) {
  const [isDark, setIsDark] = useState<boolean>(null);

  const goodNight = () => {
    setIsDark(true);
  };

  const goodMorning = () => {
    setIsDark(false);
  };
  const value = { isDark, goodNight, goodMorning };

  return (
    <>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </>
  );
}
