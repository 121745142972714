import Link from "next/link";
import PropTypes from "prop-types";

const StyledLink = ({ text, href, className }) => {
  return (
    <Link href={href} key={(Math.random() + Math.random()) * 175}>
      <a
        className={`transition-all text-sm text-gray-500 font-medium capitalize hover:text-ifixers ${className}`}
      >
        {text}
      </a>
    </Link>
  );
};

StyledLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default StyledLink;
