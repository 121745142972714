/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SVG from "react-inlinesvg";
import Link from "next/link";

export default function FlyoutMenu({ label, data, dark }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
             ${dark
                ? "text-slate-200 hover:text-slate-200"
                : "text-gray-600 hover:text-slate-800"
              } group inline-flex items-center rounded-md text-base font-semibold focus:outline-none `}
          >
            <span>{label}</span>
            <ChevronDownIcon
              className={`mt-1 h-5 w-5 transition duration-150 ease-in-out  ${dark
                ? "text-slate-300 group-hover:text-slate-200"
                : "text-gray-600 group-hover:text-slate-800"
                }`}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3  -translate-x-1/2 transform px-2 ">
              {({ close }) => (
                <div className="flex flex-row overflow-hidden min-w-max rounded-lg border border-slate-100 bg-white py-4 px-4 shadow-lg ring-1 ring-ifixers ring-opacity-5">
                  {data.map((section) => {
                    return (
                      <div key={section.id}>
                        {/* <span className="pb-4 text-lg font-medium text-slate-800">
                        {section.label}
                      </span> */}
                        <div>
                          {section.links
                            .sort((a, b) => a.id - b.id)
                            .map((link) => {
                              return (
                                <Link href={link.href} key={link.id} passHref>
                                  <div onClick={() => close()} className=" flex cursor-pointer flex-row items-center space-x-2 rounded-md p-1.5 text-slate-600 transition hover:bg-slate-200">
                                    <SVG
                                      src={link.icon}
                                      className="h-6 w-6 fill-transparent"
                                    />
                                    <div className="">
                                      <p className="min-w-max text-base font-medium leading-none text-gray-600">
                                        {link.label}{" "}
                                        {link.showIsNewTag && (
                                          <span className="ml-1.5 inline-flex items-center justify-center rounded-full bg-gradient-to-tr from-red-500 to-yellow-300 px-2 pt-1 pb-1.5 text-xs font-medium leading-none leading-5 text-white">
                                            new
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>)}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
