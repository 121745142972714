import { MailIcon } from "@heroicons/react/solid";
import { PhoneIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const HeaderCentered = ({ data }) => {
  const { scrollYProgress } = useViewportScroll();

  const headerY = useTransform(
    scrollYProgress,
    [0, 0.2, 0.3],
    ["0%", "0%", "-100%"]
  );


  return (
    <motion.div
      className={` bg-slate-200 w-full transition block z-0`}
      style={{ top: 0, y: headerY }}
    >
      <div className="container flex justify-between space-x-4 py-2">
        <div className="flex items-center justify-center font-semibold text-gray-500 text-center">
          <span className="lg:max-w-xl sm:text-left text-sm text-center">{data?.message}</span>
        </div>
        <div className="flex flex-row items-center justify-center space-x-5 text-gray-500 transition hover:text-gray-700">
          {/* <Link href={data.links[0].href}>
            <a className="text-xs  underline">{data.links[0].label}</a>
          </Link> */}
          <div className="hidden lg:flex flex-row space-x-4"> <a
            className="flex cursor-pointer flex-row items-center text-sm text-gray-500 transition hover:text-gray-700"
            href={`tel:${data?.phone.extension}${data?.phone.number}`}
          >
            <PhoneIcon className="mr-1 h-4 w-4" />
            {data?.phone.formatted}
          </a>
            <a
              className="flex cursor-pointer flex-row items-center text-sm text-gray-500 transition hover:text-gray-700"
              href={`mailto:${data?.email.email}`}
            >
              <MailIcon className="mr-1 h-4 w-4" />
              {data?.email.email}
            </a></div>

          <a
            href="https://www.instagram.com/ifixers_be/"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer text-sm text-gray-500 transition hover:text-gray-700 hidden lg:block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default HeaderCentered;
