import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";

const LanguageDropdownMenu = ({ className, dark }) => {
  const router = useRouter();
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);
  const [lang, setLang] = useState(router.locale || router.defaultLocale);
  useEffect(() => {
    setCookie("NEXT_LOCALE", lang, { maxAge: 31536000 });
    return router.push(router.asPath, router.asPath, { locale: lang });
  }, [lang, setCookie]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Dropdown
      label="Taal"
      options={router.locales}
      selected={lang}
      setSelected={setLang}
      className={className}
      dark={dark}
    />
  );
};

export default LanguageDropdownMenu;
