import {
  useEffect,
  useReducer,
  createContext,
  useContext,
  useMemo,
} from "react";
import { AppReducer, initialState } from "./AppReducer";

const AppContext = createContext();

export const AppWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  useEffect(() => {
    if (
      JSON.parse(window.localStorage.getItem("state")) &&
      state === initialState
    ) {
      //checking if there already is a state in localstorage
      dispatch({
        type: "init_stored",
        value: JSON.parse(window.localStorage.getItem("state")),
        //if yes, update the current state with the stored one
      });
    }
  });

  useEffect(() => {
    if (state !== initialState) {
      window.localStorage.setItem("state", JSON.stringify(state));
      //create and/or set a new localstorage variable called "state"
    }
  }, [state]);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
